import React, { FC, useState } from "react";
import { Box, Drawer, List, ListItemButton, styled, Typography, IconButton, Tooltip, useMediaQuery, Theme } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ScrollBar from "simplebar-react";
import { useNavigate } from "react-router-dom";
import topMenuList from "./topMenuList";

// root component interface
interface SideNavBarProps {
  showMobileSideBar: boolean;
  closeMobileSideBar: () => void;
}

// Custom styles
const MainMenu = styled(Box)(({ theme }) => ({
  left: 0,
  width: "auto",
  height: "100%",
  position: "fixed",
  boxShadow: theme.shadows[2],
  transition: "all 0.3s ease",
  zIndex: theme.zIndex.drawer + 11,
  backgroundColor: theme.palette.background.paper,
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  marginBottom: "1rem",
  justifyContent: "flex-start",
  "&:hover": { backgroundColor: "transparent" },
}));

// Sidebar Component
const DashboardSideBar: FC<SideNavBarProps> = ({ showMobileSideBar, closeMobileSideBar }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [active, setActive] = useState("Dashboard");
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const handleActiveMainMenu = (menuItem: any) => () => {
    setActive(menuItem.title);
    navigate(menuItem.path);
    closeMobileSideBar();
  };

  const toggleSidebar = () => setExpanded((prev) => !prev);

  // Sidebar content
  const mainSideBarContent = (
    <List sx={{ height: "100%" }}>
      <StyledListItemButton sx={{ justifyContent: "center" }} disableRipple>

        <img src="/assets/images/logo.png" alt="Logo" width={50} onClick={() => navigate('/dashboard')} />
      </StyledListItemButton>

      <ScrollBar style={{ maxHeight: "calc(100% - 50px)" }}>
        {topMenuList.map((nav, index) => (
          <Tooltip title={nav.title} placement="right" key={index} disableHoverListener={expanded}>
            <StyledListItemButton
              disableRipple
              onClick={handleActiveMainMenu(nav)}
              sx={{ justifyContent: expanded ? "flex-start" : "center" }}
            >
              <nav.Icon
                sx={{
                  color: active === nav.title ? "primary.main" : "secondary.400",
                }}
              />
              {expanded && (
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {nav.title}
                </Typography>
              )}
            </StyledListItemButton>
          </Tooltip>
        ))}
      </ScrollBar>
    </List>
  );

  // Mobile Sidebar
  if (downMd) {
    return (
      <Drawer
        anchor="left"
        open={showMobileSideBar}
        onClose={closeMobileSideBar}
        PaperProps={{ sx: { width: expanded ? 200 : 80 } }}
      >
        {mainSideBarContent}
      </Drawer>
    );
  }

  // Desktop Sidebar with Edge Toggle Button
  return (
    <MainMenu sx={{ width: expanded ? 200 : 80 }}>
      {/* Toggle Button Positioned on the Edge */}
      <IconButton
        onClick={toggleSidebar}
        sx={{
          position: "absolute",
          top: "10%",
          right: expanded ? -20 : -20, // Position outside the sidebar
          transform: "translateY(-50%)", // Center vertically
          backgroundColor: "white",
          boxShadow: 2,
          borderRadius: "50%",
          "&:hover": {
            backgroundColor: "#f0f0f0",
          },
        }}
      >
        {expanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>

      {/* Sidebar Content */}
      {mainSideBarContent}
    </MainMenu>
  );
};

export default DashboardSideBar;