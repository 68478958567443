import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const JobIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M20,6H16V4a2,2,0,0,0-2-2H10A2,2,0,0,0,8,4V6H4A2,2,0,0,0,2,8V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V8A2,2,0,0,0,20,6ZM10,4h4V6H10ZM20,19a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V8H20Z" />
    </SvgIcon>
  );
};

export default JobIcon;