import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const ReviewIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <path d="M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4ZM20,6l-8,5L4,6Zm0,12H4V8l8,5,8-5Z" />
            <path d="M12 20c-1.1 0-2 .9-2 2s.9 2 2 2c1.1 0 2-.9 2-2s-.9-2-2-2zm-1.73-3.55c-.1-.05-.2-.1-.31-.15-1.1-.48-2.38-.48-3.5-.01a2.76 2.76 0 0 0-.5.25C4.56 17.35 3 18.67 3 20.15V21h10v-.84c0-1.48-1.56-2.79-4.27-3.46z" />
        </SvgIcon>
    );
};

export default ReviewIcon;