import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const EmailIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4ZM20,6l-8,5L4,6Zm0,12H4V8l8,5,8-5Z" />
    </SvgIcon>
  );
};

export default EmailIcon;